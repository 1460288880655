import React from "react";
import Attendance from "../components/attendance/attendance";
class AttendanceSection extends React.Component {

    render() {
        return(
            <div>
                <Attendance />
            </div>
        )
    }
}

export default AttendanceSection;
